import { useSelector } from "react-redux";

import { useMorphedPackActions } from "store/MorphedPackSlice";
import { appRoutes } from "routes";
import { jjLogger } from "utils/logUtils";
import { PACK_ERROR_MESSAGES, PACK_ERROR_CODES, PACK_ERROR_POINTERS } from "enums/syai";
import MorphedPackPresenter from "presenters/MorphedPackPresenter";
import MorphablePackGroupPresenter from "presenters/MorphablePackGroupPresenter";
import { deserializeErrors } from "utils/storeUtils";

import usePolling from "./usePolling";
import useRouter from "./useRouter";
import useNotifications from "./useNotifications";
import useLastEcard from "./useLastEcard";

const useMorphedPack = () => {
  const {
    loadMorphedPack,
    createMorphedPack,
    generatePreviewPhoto,
    generateStandardPhotos,
    getStatus,
    loadMorphedPacks,
    loadMoreMorphedPacks,
  } = useMorphedPackActions();
  const { morphedPack, morphedPacks, meta } = useSelector((state) => state.MorphedPackSlice);
  const { ecard } = useLastEcard();
  const { push } = useRouter();
  const { stopPolling } = usePolling();
  const { addErrorNotification } = useNotifications();
  const { clearLastEcard } = useLastEcard();

  const handleStatusError = (errorCode, morphablePackGroupSlug) => {
    const notifyAndRedirect = (message, path) => {
      addErrorNotification(message);
      push(path);
    };

    const pack = morphedPack || ecard;
    const packGroupSlug =
      morphablePackGroupSlug || MorphablePackGroupPresenter.id(MorphedPackPresenter.morphedPackGroup(pack));

    switch (errorCode) {
      case PACK_ERROR_CODES.INVALID_SOURCE_PHOTO:
        notifyAndRedirect(PACK_ERROR_MESSAGES.INVALID_SOURCE_PHOTO, appRoutes.morphablePackGroupPath(packGroupSlug));
        break;

      case PACK_ERROR_CODES.INVALID_TARGET_PHOTO:
        notifyAndRedirect(PACK_ERROR_MESSAGES.INVALID_TARGET_PHOTO, appRoutes.starringYouAIPath());
        break;

      case PACK_ERROR_CODES.INTERNAL_API_FAILURE:
        notifyAndRedirect(PACK_ERROR_MESSAGES.INTERNAL_API_FAILURE, appRoutes.starringYouAIPath());
        break;

      default:
        break;
    }
  };

  const pollingLimitReached = () => {
    jjLogger.logError("useMorphedPack.js: pollingLimitReached() Polling limit reached");
    handleStatusError(PACK_ERROR_CODES.INTERNAL_API_FAILURE);
  };

  const pollStatus = async (morphedPackSlug, expectedStatus, isPreview = false) => {
    try {
      const packSlug = morphedPackSlug || MorphedPackPresenter.id(morphedPack);
      const { data } = await getStatus(packSlug);
      const { status } = data;

      if (status === expectedStatus) {
        stopPolling();

        if (isPreview) {
          push(appRoutes.syaiPackPreviewPath(packSlug));
        } else {
          push(appRoutes.syaiPackViewPath(packSlug, "0"));
          clearLastEcard();
        }
      }
    } catch (error) {
      if (error?.response?.data?.error) {
        const { code, status, message } = error.response.data.error;
        handleStatusError(code);
        jjLogger.logError(
          `useMorphedPack.js: pollStatus() Error polling for pack status: ${message} (code: ${code}, status: ${status})`,
        );
      } else {
        jjLogger.logError(`useMorphedPack.js: pollStatus() Error polling for pack status: ${error}`);
      }
      stopPolling();
    }
  };

  const handleMorphedPackError = (error, morphablePackGroupSlug) => {
    switch (error) {
      case PACK_ERROR_POINTERS.SOURCE_PHOTO:
      case PACK_ERROR_POINTERS.PHOTO_TYPE:
        handleStatusError(PACK_ERROR_CODES.INVALID_SOURCE_PHOTO, morphablePackGroupSlug);
        break;
      case PACK_ERROR_POINTERS.PHOTO_PACK_SLUG:
        handleStatusError(PACK_ERROR_CODES.INVALID_TARGET_PHOTO, morphablePackGroupSlug);
        break;
      default:
        break;
    }
  };

  const saveMorphedPack = async (sourceId, type, packSlug, packGroupSlug) => {
    const params = {
      data: {
        type: "morphed-packs",
        attributes: {
          "source-photo": sourceId,
          "photo-type": type,
          "photo-pack-slug": packSlug,
        },
      },
    };

    try {
      return await createMorphedPack(params);
    } catch (error) {
      const { response } = error;
      if (response?.data?.errors) {
        const [firstError] = response.data.errors;
        const errorToHandle = firstError?.source?.pointer?.split("/").pop();

        handleMorphedPackError(errorToHandle, packGroupSlug);
      }

      const deserializedErrors = deserializeErrors(error);
      jjLogger.logError(`useMorphedPack.js: saveMorphedPack() Error saving morphed pack: ${deserializedErrors}`);
      throw new Error(deserializedErrors);
    }
  };

  const generatePreview = async (morphedPackSlug) => {
    try {
      await generatePreviewPhoto(morphedPackSlug);
    } catch (error) {
      jjLogger.logError(`useMorphedPack.js: generatePreview() Error generating preview photos: ${error}`);
    }
  };

  const generatePhotos = async (morphedPackSlug) => {
    try {
      await generateStandardPhotos(morphedPackSlug);
    } catch (error) {
      jjLogger.logError(`useMorphedPack.js: generatePhotos() Error generating standard photos: ${error}`);
    }
  };

  return {
    loadMorphedPack,
    saveMorphedPack,
    morphedPack,
    generateStandardPhotos,
    getStatus,
    pollStatus,
    generatePreview,
    generatePhotos,
    loadMorphedPacks,
    morphedPacks,
    loadMoreMorphedPacks,
    meta,
    pollingLimitReached,
  };
};

export default useMorphedPack;
