const TEMPLATE_GROUP_TYPE_NAMES = {
  starringYouGif: "Starring You Gif",
  straightUpEcard: "Straight Up Ecard",
  starringYouEcard: "Starring You Ecard",
  starringYouClip: "Starring You Clip",
  starringYouVertical: "Starring You Vertical",
  starringYouInvite: "Starring You Invite",
  starringYouInviteGif: "Starring You Invite Gif",
};

const TEMPLATE_GROUP_TYPES = {
  straightUpEcard: "straight_up",
  starringYouEcard: "starring_you",
  starringYouClip: "starring_you_clip",
  starringYouVertical: "starring_you_vertical",
  starringYouInvite: "starring_you_invite",
  starringYouInviteGif: "starring_you_invite_gif",
  starringYouAI: "morphable_pack_group",
};

Object.freeze(TEMPLATE_GROUP_TYPE_NAMES);
Object.freeze(TEMPLATE_GROUP_TYPES);

export { TEMPLATE_GROUP_TYPE_NAMES, TEMPLATE_GROUP_TYPES };
